/* src/styles/default.module.css */

.total-container {
    margin: 1rem;
    justify-content: center;
    align-items: center;
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.back-arrow {
    font-size: 2rem;
}

.header-title {
    font-size: 2rem;
}

.logos-content {
    display: flex;
    gap: 1rem;
}