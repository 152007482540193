/* Home.module.css */

.container {
    margin: 40px auto;
    padding: 30px;
    max-width: 900px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    font-family: 'Roboto', sans-serif;
    color: #333;
}

.title {
    font-size: 2.5rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 700;
}

.byline {
    font-size: 1.2rem;
    color: #34495e;
    text-align: center;
    margin-bottom: 2rem;
    font-style: italic;
}

.subtitle {
    font-size: 1.3rem;
    color: #eaff00c4;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 300;
}

.subtitle {
    font-size: 1rem;
    color: #7f8c8d;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 200;
}

.entryList {
    list-style-type: none;
    padding: 0;
}

.entryItem {
    margin-bottom: 15px;
}

.entryLink {
    font-size: 1.2rem;
    color: #2980b9;
    text-decoration: none;
    padding: 15px;
    display: block;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    background-color: #ecf0f1;
    font-weight: 500;
}

.entryLink:hover {
    background-color: #3498db;
    color: #ecf0f1;
    transform: translateY(-3px);
}

.languageToggleButton {
    padding: 12px 24px;
    margin: 20px 0;
    background-color: #27ae60;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.languageToggleButton:hover {
    background-color: #2ecc71;
}

header {
    text-align: center;
    margin: 2rem 0;
}

.alephSpaceHeader {
    font-size: 3rem; /* Large and prominent */
    font-weight: 700; /* Bold for emphasis */
    color: #FFFFFF; /* White text for contrast */
    font-family: 'Roboto', sans-serif; /* Clean and modern font */
    text-transform: uppercase; /* Consistent with the title style */
    letter-spacing: 2px; /* Slightly increased spacing for elegance */
    margin: 0;
}

.alephSpaceTagline {
    font-size: 1.2rem; /* Smaller font for the tagline */
    font-weight: 400; /* Regular weight for subtlety */
    color: #CCCCCC; /* Light gray for a softer look */
    font-family: 'Roboto', sans-serif;
    margin-top: 0.5rem;
}

/* Link Styles */
.alephSpaceText {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    letter-spacing: 1px;
    margin: 1rem;
    transition: all 0.3s ease-in-out;
}

.alephSpaceText:hover {
    color: #FFD700; /* Gold hover effect */
    text-shadow: 0px 4px 8px rgba(255, 215, 0, 0.5); /* Glow effect */
    transform: scale(1.05); /* Slight scaling */
}

/* Special Text Styles */
.spaecialText {
    font-size: 1.2rem;
    font-weight: 600;
    color: #6C9FC4;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.5px;
    margin-left: 1rem;
    transition: all 0.3s ease-in-out;
}

.spaecialText:hover {
    color: #4A7BA7;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
}

.portfolioButton {
    padding: 10px 20px;
    margin: 20px 0;
    background-color: transparent;
    color: #2980b9;
    border: 2px solid #2980b9;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-decoration: none; /* Remove default underline from links */
}

.portfolioButton:hover {
    background-color: #2980b9;
    color: #ffffff;
    transform: translateY(-2px);
}